import React, { useState, useEffect, useRef, useCallback } from 'react';
import axios from 'axios';
import { 
  Container, Box, TextField, Button, Typography, Paper, 
  CircularProgress, Grid, ThemeProvider, createTheme,
  AppBar, Toolbar, IconButton, Menu, MenuItem
} from '@mui/material';
import { styled } from '@mui/system';
import AccountCircle from '@mui/icons-material/AccountCircle';
import { LoadScript, GoogleMap, Marker, DirectionsService, DirectionsRenderer, Autocomplete } from '@react-google-maps/api';
import './App.css';

// Создаем тему
const theme = createTheme();

// Styled components for chat bubbles
const ChatBubble = styled('div')(({ theme, isUser }) => ({
  background: isUser ? theme.palette.primary.main : theme.palette.grey[200],
  color: isUser ? theme.palette.primary.contrastText : theme.palette.text.primary,
  padding: theme.spacing(1, 2),
  borderRadius: 20,
  maxWidth: '70%',
  wordWrap: 'break-word',
  marginBottom: theme.spacing(1),
  alignSelf: isUser ? 'flex-end' : 'flex-start',
}));

const ChatContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  height: '300px',
  overflowY: 'auto',
  padding: '16px',
});

// Координаты центра Сан-Франциско
const SF_CENTER = { lat: 37.7749, lng: -122.4194 };

// Используем предоставленный API ключ
const GOOGLE_MAPS_API_KEY = "AIzaSyAVblIxhaUMCF_86qvTxT7P-zXA6WLfAfM";

// Опции для карты Google
const mapOptions = {
  disableDefaultUI: true,
  zoomControl: true,
  mapTypeControl: false,
  streetViewControl: false,
  fullscreenControl: false,
};

function App() {
  const [token, setToken] = useState('');
  const [fromAddress, setFromAddress] = useState('');
  const [toAddress, setToAddress] = useState('');
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [threadId, setThreadId] = useState(null);
  const [messages, setMessages] = useState([]);
  const [inputMessage, setInputMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [fromCoords, setFromCoords] = useState(null);
  const [toCoords, setToCoords] = useState(null);
  const messagesEndRef = useRef(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [autocompleteFrom, setAutocompleteFrom] = useState(null);
  const [autocompleteTo, setAutocompleteTo] = useState(null);
  const [directions, setDirections] = useState(null);
  const [map, setMap] = useState(null);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onLoadFrom = (autocomplete) => {
    setAutocompleteFrom(autocomplete);
  }

  const onLoadTo = (autocomplete) => {
    setAutocompleteTo(autocomplete);
  }

  const onPlaceChangedFrom = () => {
    if (autocompleteFrom !== null) {
      const place = autocompleteFrom.getPlace();
      setFromAddress(place.formatted_address);
      setFromCoords({ lat: place.geometry.location.lat(), lng: place.geometry.location.lng() });
    }
  }

  const onPlaceChangedTo = () => {
    if (autocompleteTo !== null) {
      const place = autocompleteTo.getPlace();
      setToAddress(place.formatted_address);
      setToCoords({ lat: place.geometry.location.lat(), lng: place.geometry.location.lng() });
    }
  }

  const login = async (password) => {
    try {
      const formData = new URLSearchParams();
      formData.append('username', '');
      formData.append('password', password);

      const response = await axios.post('http://ec2-35-173-205-107.compute-1.amazonaws.com:8000/token', formData, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      });
      setToken(response.data.access_token);
      setIsAuthenticated(true);
    } catch (error) {
      console.error('Login error:', error);
      alert('Incorrect password, please try again.');
    }
  };

  const createDialog = async () => {
    setLoading(true);
    try {
      const response = await axios.post('http://ec2-35-173-205-107.compute-1.amazonaws.com:8000/dialogs/', {}, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      console.log('Create dialog response:', response.data);
      setThreadId(response.data.thread_id);
      console.log('Thread ID set:', response.data.thread_id);
      if (response.data.message) {
        setMessages([{sender: response.data.message.sender, text: response.data.message.text}]);
      } else {
        setMessages([]);
      }
    } catch (error) {
      console.error('Failed to create dialog:', error);
    } finally {
      setLoading(false);
    }
  };

  const sendMessage = async () => {
    if (inputMessage.trim() === '') {
      return;
    }

    const newMessage = {sender: 'user', text: inputMessage};
    setMessages(prevMessages => [...prevMessages, newMessage]);
    setInputMessage('');

    try {
      const response = await axios.post(`http://ec2-35-173-205-107.compute-1.amazonaws.com:8000/dialogs/${threadId}/messages/`, {
        sender: 'user',
        text: inputMessage
      }, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      if (response.data.message) {
        setMessages(prevMessages => [...prevMessages, { sender: response.data.message.sender, text: response.data.message.text }]);
      }
    } catch (error) {
      console.error('Failed to send message:', error);
    }
  };

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({behavior: 'smooth'});
  }, [messages]);

  const directionsCallback = useCallback((result, status) => {
    if (status === 'OK') {
      setDirections(result);
    }
  }, []);

  const onMapLoad = useCallback((map) => {
    setMap(map);
  }, []);

  useEffect(() => {
    if (map && fromCoords && toCoords) {
      const bounds = new window.google.maps.LatLngBounds();
      bounds.extend(fromCoords);
      bounds.extend(toCoords);
      map.fitBounds(bounds);
    } else if (map && (fromCoords || toCoords)) {
      map.setCenter(fromCoords || toCoords);
      map.setZoom(15);
    }
  }, [map, fromCoords, toCoords]);

  return (
    <ThemeProvider theme={theme}>
      <LoadScript googleMapsApiKey={GOOGLE_MAPS_API_KEY} libraries={["places"]}>
        <Box sx={{ flexGrow: 1 }}>
          <AppBar position="static">
            <Toolbar>
              <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                Д
              </Typography>
              <Box sx={{ display: 'flex', justifyContent: 'center', flexGrow: 1 }}>
                <Button color="inherit">Home</Button>
                <Button color="inherit">Services</Button>
                <Button color="inherit">Partners</Button>
              </Box>
              <div>
                <IconButton
                  size="large"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleMenu}
                  color="inherit"
                >
                  <AccountCircle />
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  <MenuItem onClick={handleClose}>Login</MenuItem>
                </Menu>
              </div>
            </Toolbar>
          </AppBar>
        </Box>
        <Container maxWidth="lg" sx={{ mt: 4 }}>
          {!isAuthenticated ? (
            <Box display="flex" flexDirection="column" alignItems="center" mt={5}>
              <Typography variant="h4" gutterBottom>Enter Password to Access</Typography>
              <TextField
                type="password"
                label="Password"
                variant="outlined"
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    login(e.target.value);
                  }
                }}
              />
            </Box>
          ) : (
            <Box>
              <Grid container spacing={2} sx={{ height: '600px' }}>
                <Grid item xs={12} md={6} sx={{ height: '100%' }}>
                  <Paper elevation={3} sx={{ height: '100%', display: 'flex', flexDirection: 'column', p: 3 }}>
                    {!threadId ? (
                      <>
                        <Typography variant="h5" gutterBottom>Enter Addresses</Typography>
                        <Autocomplete
                          onLoad={onLoadFrom}
                          onPlaceChanged={onPlaceChangedFrom}
                        >
                          <TextField 
                            fullWidth 
                            label="From Address" 
                            variant="outlined" 
                            sx={{mb: 2}}
                            value={fromAddress}
                            onChange={(e) => setFromAddress(e.target.value)}
                          />
                        </Autocomplete>
                        <Autocomplete
                          onLoad={onLoadTo}
                          onPlaceChanged={onPlaceChangedTo}
                        >
                          <TextField 
                            fullWidth 
                            label="To Address" 
                            variant="outlined" 
                            sx={{mb: 2}}
                            value={toAddress}
                            onChange={(e) => setToAddress(e.target.value)}
                          />
                        </Autocomplete>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={createDialog}
                          disabled={!fromAddress || !toAddress || loading}
                        >
                          {loading ? <CircularProgress size={24}/> : 'Next'}
                        </Button>
                      </>
                    ) : (
                      <>
                        <Typography variant="h5" gutterBottom>Chat</Typography>
                        <ChatContainer sx={{ flexGrow: 1, mb: 2 }}>
                          {messages.length === 0 ? (
                            <Typography>No messages yet.</Typography>
                          ) : (
                            messages.map((message, index) => (
                              <ChatBubble key={index} isUser={message.sender === 'user'}>
                                <Typography variant="body1">{message.text}</Typography>
                              </ChatBubble>
                            ))
                          )}
                          <div ref={messagesEndRef}/>
                        </ChatContainer>
                        <Box display="flex" alignItems="center">
                          <TextField
                            fullWidth
                            label="Type your message..."
                            variant="outlined"
                            value={inputMessage}
                            onChange={(e) => setInputMessage(e.target.value)}
                            onKeyDown={(e) => {
                              if (e.key === 'Enter') {
                                sendMessage();
                              }
                            }}
                          />
                          <Button variant="contained" color="primary" onClick={sendMessage} sx={{ml: 2}}>Send</Button>
                        </Box>
                      </>
                    )}
                  </Paper>
                </Grid>
                <Grid item xs={12} md={6} sx={{ height: '100%' }}>
                  <Paper elevation={3} sx={{ height: '100%' }}>
                    <GoogleMap
                      mapContainerStyle={{ height: '100%', width: '100%' }}
                      center={SF_CENTER}
                      zoom={12}
                      onLoad={onMapLoad}
                      options={mapOptions}
                    >
                      {fromCoords && <Marker position={fromCoords} />}
                      {toCoords && <Marker position={toCoords} />}
                      {fromCoords && toCoords && (
                        <DirectionsService
                          options={{
                            destination: toCoords,
                            origin: fromCoords,
                            travelMode: 'DRIVING'
                          }}
                          callback={directionsCallback}
                        />
                      )}
                      {directions && <DirectionsRenderer directions={directions} />}
                    </GoogleMap>
                  </Paper>
                </Grid>
              </Grid>
            </Box>
          )}
        </Container>
      </LoadScript>
    </ThemeProvider>
  );
}

export default App;
